import React from "react";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import Herobanner from "../components/herobanner";
import Container from "../components/containers/container";
import Box from "../components/containers/box";
import IconMiniTitle from "../components/elements/iconMiniTitle";

import ContactContainer from "../components/elements/contactContainer";

const ContattiPage = () => (
  <Layout>
    <Helmet>
      <title>Contatti</title>
      <meta
        name='description'
        content='Ci piace definirci affidabili e all’avanguardia: le nostre soluzioni rendono le aziende più competitive e pronte per affrontare il futuro.Contattaci per saperne di più.'
      />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='' />
      <meta property='og:image' content='/image/social/og-contatti-elettronica-scarpa.png' />

      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:url' content='' />
      <meta property='twitter:title' content='Contatti | Elettronica scarpa' />
      <meta
        property='twitter:description'
        content='Ci piace definirci affidabili e all’avanguardia: le nostre soluzioni rendono le aziende più competitive e pronte per affrontare il futuro.Contattaci per saperne di più.'
      />
      <meta property='twitter:image' content='/image/social/og-contatti-elettronica-scarpa.png' />
    </Helmet>
    <Herobanner background='contatti'>
      <h1 className='mb-6'>Contattaci</h1>
    </Herobanner>
    <Container notAligned={true}>
      <Box>
        <div className='row'>
          <div className='col-12 col-lg-5 offset-lg-1'>
            <IconMiniTitle>Siamo a tua disposizione</IconMiniTitle>
            <h3 className='my-4'>Se hai bisogno di aiuto o di più informazioni.</h3>
            <p>Passa a trovarci, chiamaci, manda una mail o compila il form in fondo a questa pagina: siamo a tua disposizione, se hai bisogno non esitare a metterti in contatto.</p>
          </div>
          <div className='col-12 col-lg-4 offset-lg-1'>
            <div className='c-footer-column__icon-content mt-4 mt-lg-7'>
              <div className='c-footer-column__icon geo' />
              <div className='c-footer-column__icon-text'>Via Castellengo 42/A 13836 Cossato (BI) </div>
            </div>
            <div className='c-footer-column__icon-content'>
              <div className='c-footer-column__icon phone' />
              <div className='c-footer-column__icon-text'>
                +39 015 983805 <br />
                +39 015 8853355
              </div>
            </div>
            <div className='c-footer-column__icon-content'>
              <div className='c-footer-column__icon mail' />
              <div className='c-footer-column__icon-text'>info@elettronicascarpa.it</div>
            </div>
            <div className='c-footer-column__icon-content'>
              <div className='c-footer-column__icon time' />
              <div className='c-footer-column__icon-text'>Lun - Ven: 8-12 / 14-18</div>
            </div>
            <a className='e-button e-button--block e-button--flex mt-4' href='brochure-elettronica-scarpa.pdf' title='Elettronica Scarpa - Brochure'>
              Scarica la brochure aziendale
              <svg version='1.1' id='Livello_1' x='0px' y='0px' viewBox='0 0 24 24'>
                <path className='st0' d='M19,20.5v-2H5v2H19z' />
                <g className='arrow'>
                  <polygon className='st0' points='19,9.5 15,9.5 15,3.5 9,3.5 9,9.5 5,9.5 12,16.5 	' />
                  <polygon className='st0' points='11,11.5 11,5.5 13,5.5 13,11.5 14.2,11.5 12,13.7 9.8,11.5 	' />
                </g>
              </svg>
            </a>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-lg-10 offset-lg-1'>
            <a
              href='https://www.google.it/maps/place/Elettronica+Scarpa+Srl/@45.5307786,8.1760364,17z/data=!3m1!4b1!4m5!3m4!1s0x47862477dcad500f:0x800ace31128cf94b!8m2!3d45.5307749!4d8.1782251'
              target='_blank'
              title='Scopri come raggiungere Elettronica Scarpa'
              rel='noreferrer'
            >
              <img className='w-100 mt-6' alt='map' src='/image/map.jpg' />
            </a>
          </div>
        </div>
      </Box>
    </Container>
    <Container customClass='py-10' />

    <ContactContainer />
  </Layout>
);

export default ContattiPage;
